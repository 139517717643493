import React from "react"
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, Coffee, ExternalLink, Star, Cpu, DollarSign, Feather, FastForward, CreditCard } from 'react-feather'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Box from "../../components/stats/box"
import StatRow from "../../components/stats/statrow"
import WorldMap from "../../components/worldmap"


export default class EstoniaEcosystem extends React.Component {
  constructor(props){
      super(props);

      this.state = {
          showEcosystemsLink: false,
          showNextLink: false,
          showPrevLink: false
      }

      this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
      // Esc key press
      if(event.keyCode === 27) {
          this.setState({
              showEcosystemsLink: true
          });

          setTimeout(() => {
              let linkRef = document.getElementById("esc-action");
              linkRef.click();
          }, 50)
      }

      // Left Arrow key press
      if(event.keyCode === 37) {
          this.setState({
              showPrevLink: true
          });

          setTimeout(() => {
              let prevLinkRef = document.getElementById("prev-action");
              prevLinkRef.click();
          }, 50)
      }

      // Right Arrow key press
      if(event.keyCode === 39) {
          this.setState({
              showNextLink: true
          });

          setTimeout(() => {
              let nextLinkRef = document.getElementById("next-action");
              nextLinkRef.click();
          }, 50)
      }
  }
  componentDidMount(){
      document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
      return (
  <Layout>
            {
            this.state.showEcosystemsLink &&
            <AniLink id="esc-action" fade to="/ecosystems" duration={0.4}></AniLink >
        }
        {
            this.state.showPrevLink &&
            <AniLink id="prev-action" fade to="/ecosystems" duration={0.4}></AniLink >
        }
        {
            this.state.showNextLink &&
            <AniLink id="next-action" fade to="/ecosystems/lithuania" duration={0.4}></AniLink >
        }
    <SEO title="Estonia Startup Ecosystem" description="" />
    <div id="ecosystem-top-part">
    <div id="back">
        <AniLink fade to="/ecosystems" duration={0.4}>
          <em>{'<-'}</em> All Ecosystems
        </AniLink>
      </div>
    <div id="contains-map">
    <div id="estonia_map_container">
      <WorldMap />
      </div>
      </div>
      <div id="left-col">
        <div id="left-col-content">
          <div className="page-icon-container" style={{fontSize:'48px'}}>
          <span role="img" aria-label="Estonia">🇪🇪</span>

          </div>
          <h1 id="country-header">Estonia</h1>
          <h2 className="description">Estonia proactively increases the strength of its ecosystem and truly shines when it comes to aligning incentives between the public and private sectors, and <OutboundLink className="inline-link" href="https://startupestonia.ee/focus-areas/accelerate-estonia">progressive policy-making</OutboundLink> which enables pilot programs in typically hard-to-access sectors such as finance and education. </h2>
          
          <div className="recommendation">
            <div className="recommendation-title">
            <Star className="recommendation-icon"/>
          <h2 className="recommended">
           Recommended for
          </h2>
          </div>
            <div className="recommendations">
            <OutboundLink className="recommended-niche" href="https://investinestonia.com/business-opportunities/fintech/">Fintech</OutboundLink> <OutboundLink className="recommended-niche" href="https://startupestonia.ee/focus-areas/cybertech">Cyber Security</OutboundLink><OutboundLink className="recommended-niche" href="https://startupestonia.ee/focus-areas/edtech-focus">Education</OutboundLink>
          </div>
        </div>
      </div>
      </div> 
      <div id="right-col">
      
        <AniLink fade to="/interviews/estonia" className="colled-many" duration={0.4}>
          <div className="long-container">
            
            <div className="item-header">
              <div className="long-icon-container">
                <Coffee className="slack-icon"/> 
              </div>
            Interview
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </AniLink>
              
        <AniLink fade to="/startup-visas/estonia" duration={0.4}>
          <div className="long-container">
            
            <div className="item-header">
              <div className="long-icon-container">
                <Globe className="slack-icon"/> 
              </div>
            Startup Visa
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </AniLink>

        <OutboundLink href="https://www.startupestonia.ee" target="_blank" rel="noopener noreferrer">
          <div className="external-container">
            <div className="item-header">
            <div className="long-icon-container">
              <Globe className="slack-icon"/> 
            </div>

            Official Website
              <div className="item-arrow">
              <ExternalLink className="slack-icon"/> 
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </OutboundLink>
    
      </div>
    </div>
    <div className="content-container">


            {/* Starting Up */}

            <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Strengths</h2>
          </div>
        </div>
        <div className="features-box">
          <div className="feature">
            <div className="feature-image-container">
              <CreditCard className="feature-image"/>
            </div>
            <div clasName="feature-content">
            <h3 className="feature-title">Advanced Financial System</h3>
            <p className="feature-description">Tight integration with the EU and global banking systems, making it easy for fintech companies to innovate in payments, transactions, and other financial services.</p>
            </div>
          </div>
          <div className="feature">
          <div className="feature-image-container">
              <Cpu className="feature-image"/>
            </div>
            <div clasName="feature-content">
            <h3 className="feature-title">Fully Digitized Goverment Services</h3>
            <p className="feature-description">All services are digitized enabling you to easily manage your business, incorporate, and do everything online without needing assistance.</p>
            </div>
          </div>
          <div className="feature">
            <div className="feature-image-container">
              <Feather className="feature-image"/>
            </div>
            <div clasName="feature-content">
              <h3 className="feature-title">Progressive Government Policy</h3>
              <p className="feature-description">You can potentially start pilot programs in both public and private education and healthcare institutions, and innovative solutions are generally desired.</p>
            </div>
          </div>
          <div className="feature">
          <div className="feature-image-container">
              <DollarSign className="feature-image"/>
            </div>
            <div clasName="feature-content">
            <h3 className="feature-title">Availability of Funding</h3>
            <p className="feature-description">Estonian startups have received €200M in funding in 2019, and interest is growing from investors, venture capitalists, and corporations as the ecosystem gains in momentum.</p>
            </div>
          </div>
          <div className="feature">
          <div className="feature-image-container">
              <FastForward className="feature-image"/>
            </div>
            <div clasName="feature-content">
            <h3 className="feature-title">Streamlined Immigration Process</h3>
            <p className="feature-description">It is easier than ever to move immigrate to Estonia, whether you're a founder through the <AniLink fade to="/startup-visas/estonia" duration={0.4} className="inline-link">Startup Visa</AniLink> or looking to bring in employees from abroad.</p>
            </div>
          </div>
        </div>
      </div>


            {/* Starting Up */}

        <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Starting Up</h2>
          </div>
          <div className="stat-description">
            Starting up in Estonia costs less than $1,000 to incorporate and can be done online. Hiring costs around $1,800 per employee.
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="Incorporation" value="-"/>
            <Box title="Employee" value="-"/>
            <Box title="Employee Tax" value="?"/>
            <Box title="Corporate Tax" value="0%"/>

          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">Estimated Monthly Costs of Starting up</div>
            </div>
            <StatRow firstKey="Developer" firstValue="?" secondKey="Designer" secondValue="?"/>
            <StatRow firstKey="Marketer" firstValue="?" secondKey="Sales Person" secondValue="?"/>
            <StatRow firstKey="Business Development" firstValue="?" secondKey="Account Manager" secondValue="?"/>
            <StatRow firstKey="Customer Service" firstValue="?" secondKey="Office Manager" secondValue="?"/>
            <StatRow firstKey="Lawyer" firstValue="?" secondKey="Accountant" secondValue="?"/>
            <StatRow firstKey="Utilities" firstValue="-" secondKey="Office Space" secondValue="?"/>
            <StatRow firstKey="Home Rent" firstValue="?" secondKey="Groceries" secondValue="?"/>


          </div>
        </div>
      </div>

      {/* Ecosystem */}

      <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Ecosystem</h2>
          </div>
          <div className="stat-description">
          Estonia's startup ecosystem is ranked 11th globally by <OutboundLink className="inline-link" href="https://www.startupblink.com/startups/estonia">Startup Blink</OutboundLink> in their ranking of global ecosystems.
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="Rank" value="11"/>
            <Box title="Startups" value="992"/>
            <Box title="Founders" value="-"/>
            <Box title="Employees" value="5896"/>
          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">Startups in Ecosystem by Industry</div>
            </div>
            <StatRow firstKey="Enterprise Software" firstValue="176" secondKey="Health &amp; Welness" secondValue="81"/>
            <StatRow firstKey="Ads and Creative" firstValue="118" secondKey="Clean &amp; Energy" secondValue="53"/>
            <StatRow firstKey="Finance" firstValue="123" secondKey="Education" secondValue="40"/>
            <StatRow firstKey="Consumer" firstValue="96" secondKey="Transport &amp; Logistics" secondValue="56"/>
            <StatRow firstKey="Travel &amp; Tourism" firstValue="33" secondKey="Communication" secondValue="39"/>
            <StatRow firstKey="Gaming" firstValue="35" secondKey="Food &amp; Agriculture" secondValue="27"/>
            <StatRow firstKey="Real Estate" firstValue="27" secondKey="Manufacturing" secondValue="19"/>
            <StatRow firstKey="Cyber Security" firstValue="46" secondKey="Space, Deep Tech &amp; AI" secondValue="23"/>
          </div>
        </div>
      </div>

      {/* Founders */}

      <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Founders</h2>
          </div>
          <div className="stat-description">
          According to Female Founders Monitor 2019, our proportion of female founders is similar to Germany (15%), Silicon Valley (16%), London (15%) and Singapore (12%). 
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="Immigrants" value="18%"/>
            <Box title="Women" value="15%"/>
            <Box title="Avg. Age" value="37"/>
            <Box title="Degrees" value="59%"/>
          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">Founder Nationality Distribution</div>
            </div>
            <StatRow firstKey="Estonia" firstValue="-" secondKey="Russia" secondValue="-"/>
            <StatRow firstKey="India" firstValue="-" secondKey="Nigeria" secondValue="-"/>
            <StatRow firstKey="China" firstValue="-" secondKey="Pakistan" secondValue="-"/>
            <StatRow firstKey="Turkey" firstValue="-" secondKey="Iran" secondValue="-"/>
            <StatRow firstKey="Ukraine" firstValue="-" secondKey="Morocco" secondValue="-"/>
            <StatRow firstKey="Egypt" firstValue="-" secondKey="United States" secondValue="-"/>
            <StatRow firstKey="Latvia" firstValue="-" secondKey="United Kindgom" secondValue="-"/>
            <StatRow firstKey="Italy" firstValue="-" secondKey="Other" secondValue="-"/>

          </div>
        </div>
      </div>

      {/* Startup Employees */}

      <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Startup Employees</h2>
          </div>
          <div className="stat-description">
            Startups in Estonia created 61% of all new jobs in 2019. Veriff, Transferwise, Bolt and Pipedrive hired the most employees.
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="Total" value="5235"/>
            <Box title="Remote" value="2635"/>
            <Box title="% of New Jobs" value="61%"/>
            <Box title="Women" value="46%"/>
          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">Employee Function Count</div>
            </div>
            <StatRow firstKey="Developers" firstValue="?" secondKey="Lawyers" secondValue="?"/>
            <StatRow firstKey="Designers" firstValue="?" secondKey="Accountants" secondValue="-"/>
            <StatRow firstKey="Marketers" firstValue="?" secondKey="-" secondValue="-"/>
            <StatRow firstKey="Sales People" firstValue="?" secondKey="-" secondValue="-"/>
            <StatRow firstKey="Business Development" firstValue="?" secondKey="-" secondValue="-"/>
            <StatRow firstKey="Account Managers" firstValue="?" secondKey="-" secondValue="-"/>
            <StatRow firstKey="Customer Service" firstValue="?" secondKey="-" secondValue="-"/>
            <StatRow firstKey="Office Managers" firstValue="?" secondKey="-" secondValue="-"/>
          </div>
        </div>
      </div>

      {/* Employee Compensation */}

      {/* <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Employee Wages</h2>
          </div>
          <div className="stat-description">
          Employees between 31-40 years of age are paid the largest average monthly wage of € 2,396.
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="Startup Avg." value="€ 1,706"/>
            <Box title="Country Avg." value="€ 1,245"/>
            <Box title="—" value="—"/>
          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">Average Monthly Wages by Sector (in €)</div>
            </div>
            <StatRow firstKey="Enterprise Software" firstValue="?" secondKey="Health &amp; Welness" secondValue="?"/>
            <StatRow firstKey="Ads and Creative" firstValue="?" secondKey="Clean &amp; Energy" secondValue="?"/>
            <StatRow firstKey="Finance" firstValue="2,389" secondKey="Education" secondValue="?"/>
            <StatRow firstKey="Consumer" firstValue="?" secondKey="Transport &amp; Logistics" secondValue="2,693"/>
            <StatRow firstKey="Travel &amp; Tourism" firstValue="?" secondKey="Communication" secondValue="3,460"/>
            <StatRow firstKey="Gaming" firstValue="2,699" secondKey="Food &amp; Agriculture" secondValue="?"/>
            <StatRow firstKey="Real Estate" firstValue="?" secondKey="Manufacturing" secondValue="?"/>
            <StatRow firstKey="Space" firstValue="2,571" secondKey="Deep Tech &amp; AI" secondValue="2,571"/>
          </div>
        </div>
      </div> */}

      {/* Revenue */}

      <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Startup Revenue</h2>
          </div>
          <div className="stat-description">
          The highest revenues were reported by Pipedrive (€ 22M), Comodule (€ 11.9M), Creative Mobile (€ 11M), Fiizy (€ 8.6M) and Starship Technologies (€ 7.5M).
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="2019" value="€ 394M"/>
            <Box title="2018" value="€ 357M"/>
            <Box title="2017" value="€ 105M"/>
          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">Revenue by Sector (in €)</div>
            </div>
            <StatRow firstKey="Enterprise Software" firstValue="106M" secondKey="Health &amp; Welness" secondValue="?"/>
            <StatRow firstKey="Ads and Creative" firstValue="65M" secondKey="Clean &amp; Energy" secondValue="23M"/>
            <StatRow firstKey="Finance" firstValue="46.8M" secondKey="Education" secondValue="5.4M"/>
            <StatRow firstKey="Consumer" firstValue="5.3M" secondKey="Transport &amp; Logistics" secondValue="26.1M"/>
            <StatRow firstKey="Travel &amp; Tourism" firstValue="3M" secondKey="Communication" secondValue="4.2M"/>
            <StatRow firstKey="Gaming" firstValue="35.5M" secondKey="Food &amp; Agriculture" secondValue="5.3M"/>
            <StatRow firstKey="Real Estate" firstValue="1.1M" secondKey="Manufacturing" secondValue="3.9M"/>
            <StatRow firstKey="Cyber Security" firstValue="31M" secondKey="Space, Deep Tech &amp; AI" secondValue="65M"/>
          </div>
        </div>
      </div>

      {/* Taxes */}

      {/* <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Taxes</h2>
          </div>
          <div className="stat-description">
          The biggest contributors of employment taxes among startups are Transferwise (€ 5M), Pipedrive (€ 3.7M), Bolt (€ 2.8M), Monese (€ 0.97M) and Veriff (€ 0.83M).
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="2019" value="€ 77M"/>
            <Box title="2018" value="€ 52M"/>
            <Box title="2017" value="€ 36M"/>
          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">2019 Tax Contribution by Sector (in €)</div>
            </div>
            <StatRow firstKey="Enterprise Software" firstValue="9M" secondKey="Health &amp; Welness" secondValue="8.5M"/>
            <StatRow firstKey="Ads and Creative" firstValue="12.3M" secondKey="Clean &amp; Energy" secondValue="14.7M"/>
            <StatRow firstKey="Finance" firstValue="9.6M" secondKey="Education" secondValue="?"/>
            <StatRow firstKey="Consumer" firstValue="?" secondKey="Transport &amp; Logistics" secondValue="4M"/>
            <StatRow firstKey="Travel &amp; Tourism" firstValue="?" secondKey="Communication" secondValue="?"/>
            <StatRow firstKey="Gaming" firstValue="1.8M" secondKey="Food &amp; Agriculture" secondValue="?"/>
            <StatRow firstKey="Real Estate" firstValue="1.1M" secondKey="Manufacturing" secondValue="?"/>
            <StatRow firstKey="Cyber Security" firstValue="?" secondKey="Space, Deep Tech &amp; AI" secondValue="?"/>
          </div>
        </div>
      </div> */}


      {/* Venture Capital */}

      <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Venture Capital</h2>
          </div>
          <div className="stat-description">
            Estonian startups receive 10%? of all Venture Funding in Europe outside of the UK.
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="2019 Funding" value="€ 258M"/>
            <Box title="Deals" value="65"/>
            <Box title="Avg. Deal Size" value="€ 4.43M"/>
          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">2019 Funds Raised by Sector (in €)</div>
            </div>
            <StatRow firstKey="Enterprise Software" firstValue="9M" secondKey="Health &amp; Welness" secondValue="?"/>
            <StatRow firstKey="Ads and Creative" firstValue="12.3M" secondKey="Clean &amp; Energy" secondValue="14.7M"/>
            <StatRow firstKey="Finance" firstValue="9.6M" secondKey="Education" secondValue="?"/>
            <StatRow firstKey="Consumer" firstValue="?" secondKey="Transport &amp; Logistics" secondValue="4M"/>
            <StatRow firstKey="Travel &amp; Tourism" firstValue="?" secondKey="Communication" secondValue="?"/>
            <StatRow firstKey="Gaming" firstValue="1.8M" secondKey="Food &amp; Agriculture" secondValue="?"/>
            <StatRow firstKey="Real Estate" firstValue="?" secondKey="Manufacturing" secondValue="?"/>
            <StatRow firstKey="Space" firstValue="?" secondKey="Deep Tech &amp; AI" secondValue="?"/>
          </div>
        </div>
      </div>


      {/* Startup Visas */}

      <div className="stat-container">
        <div className="stat-info">
          <div className="stat-title">
            <h2>Startup Visa</h2>
          </div>
          <div className="stat-description">
          Applicants are assessed by Tehnopol, Garage48, Startup Leaders Club, Estonian Business Angels Network, Startup Wise Guys, Superangel and Startup Estonia.
          </div>
        </div>
        <div className="stats-box">
          <div className="stat-summary">
            <Box title="Applicants" value="1106"/>
            <Box title="Accepted" value="907"/>
            <Box title="Success Rate" value="-"/>

          </div>
          <div className="stat-table">
            <div className="rt-tr-group" role="rowgroup">
              <div className="table-title">Startup Visa Applications by Country</div>
            </div>
            <StatRow firstKey="Estonia" firstValue="3733" secondKey="Russia" secondValue="?"/>
            <StatRow firstKey="India" firstValue="?" secondKey="Nigeria" secondValue="?"/>
            <StatRow firstKey="China" firstValue="?" secondKey="Pakistan" secondValue="?"/>
            <StatRow firstKey="Turkey" firstValue="?" secondKey="Iran" secondValue="?"/>
            <StatRow firstKey="Ukraine" firstValue="?" secondKey="Morocco" secondValue="?"/>
            <StatRow firstKey="Egypt" firstValue="?" secondKey="United States" secondValue="?"/>
            <StatRow firstKey="Latvia" firstValue="?" secondKey="United Kindgom" secondValue="?"/>
            <StatRow firstKey="Italy" firstValue="?" secondKey="Other" secondValue="242"/>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)}
      }